import { type ComboboxItem, type ComboboxProps, Group, Highlight, Text, ThemeIcon } from '@mantine/core';
import { forwardRef } from 'react';
import { highlightStyles } from './Highlight';

type ExtendedSelectItem = ComboboxItem & {
  color?: string;
  size?: ComboboxProps['size'];
  query?: string;
};

export const ColorDot: React.FC<{ color: string }> = ({ color }) => {
  if (!color) return null;
  return (
    <ThemeIcon size={'xs'} radius="xl" color={color}>
      {''}
    </ThemeIcon>
  );
};
export const SelectItemComponent = forwardRef<HTMLDivElement, ExtendedSelectItem>(
  ({ query, label, value, color = '', size = 'sm', ...others }: ExtendedSelectItem, ref) => {
    const displayedValue = label ?? value;
    return (
      <Group ref={ref} {...others}>
        <ColorDot color={color} />
        {query ? (
          <Highlight
            highlightStyles={highlightStyles}
            color={'violet.3'}
            fw={500}
            highlight={query}
            size={size}
          >
            {displayedValue}
          </Highlight>
        ) : (
          <Text fw={500} size={size}>
            {displayedValue}
          </Text>
        )}
      </Group>
    );
  },
);

SelectItemComponent.displayName = 'SelectItemComponent';
