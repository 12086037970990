import { Avatar, type ComboboxItem, Group, Text } from '@mantine/core';
import { IconKey, IconUser, IconUserExclamation } from '@tabler/icons-react';
import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTokens } from '~/Components/Settings/Api/tokens.hook';
import { clerkClientUtils } from '~/core/auth/clerk.utils';
import { useMemberships } from '~/ui/hooks/organization-memberships.hook';
import { FormSelect } from '../Select/Select';
import { FormSelectProps } from '../Select/types';
import { type IBaseFormInputProps } from '../shared/FormInputTypes';

export const User: React.FC<{ userId: string }> = ({ userId }) => {
  const memberships = useMemberships();
  const { tokens = [] } = useTokens();
  const { name, avatar } = useMemo(() => {
    const member = memberships?.data?.find((member) => member.publicUserData.userId === userId);
    if (member)
      return {
        name: clerkClientUtils.getFullName(member),
        avatar: <Avatar src={member.publicUserData.imageUrl} size={'xs'} />,
      };

    const token = tokens.find((token) => token.id === userId);
    if (token) return { name: token.name, kind: 'API_TOKEN', avatar: <IconKey size={'1em'} /> };
    return {
      name: (
        <Text fz={'sm'} truncate="end">
          {userId}
        </Text>
      ),
      avatar: <IconUserExclamation size={'1rem'} />,
    };
  }, [tokens, memberships?.data, userId]);

  return (
    <Group gap={'xs'} maw={150} wrap="nowrap">
      {avatar}
      {name}
    </Group>
  );
};

export const FormUserSelect: React.FC<IBaseFormInputProps & Omit<FormSelectProps, 'data'>> = ({
  name,
  ...props
}) => {
  const { t } = useTranslation('fieldsComponent');
  const memberships = useMemberships();
  const data =
    memberships?.data?.map((member) => ({
      label: clerkClientUtils.getFullName(member),
      value: member.publicUserData.userId!,
      avatar: member.publicUserData.imageUrl,
    })) ?? [];

  return (
    <FormSelect
      loading={memberships?.isLoading}
      placeholder={t('Select user...')}
      name={name}
      icon={<IconUser size="1rem" />}
      itemComponent={Person}
      {...props}
      data={data}
    />
  );
};

export type PersonSelectItem = ComboboxItem & { avatar?: string | null };

export const Person = forwardRef<HTMLDivElement, PersonSelectItem>(
  ({ label, value, avatar, ...others }: PersonSelectItem, ref) => {
    return (
      <Group ref={ref} {...others} gap={'xs'}>
        <Avatar src={avatar} size={'xs'} />
        {label}
      </Group>
    );
  },
);

Person.displayName = 'Person';
