import { useAuth } from '@clerk/nextjs';
import { type ComboboxItem } from '@mantine/core';
import { useParams, usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { api } from '../../trpc';

export const useCardTypes = () => {
  const { slug, id } = useParams<{ slug?: string; id?: string }>() ?? {};
  const pathname = usePathname();
  const actualSlug = slug ?? pathname?.split('/')[2]; // /cards/contacts -> contacts
  const { isSignedIn, isLoaded, orgId } = useAuth();
  const { data: cardTypes, isLoading } = api.cardTypes.getAll.useQuery(undefined, {
    enabled: Boolean(isSignedIn) && isLoaded && Boolean(orgId),
    staleTime: 2 * 60 * 60 * 1000,
    gcTime: 5 * 60 * 60 * 1000,
  });

  const filteredCardTypes = useMemo(
    () => cardTypes?.filter((cardType) => cardType.settings?.isHidden !== true) ?? [],
    [cardTypes],
  );

  const cardTypeOptions = useMemo<ComboboxItem[]>(() => {
    return (
      filteredCardTypes?.map((cardType) => ({
        label: `${cardType.pluralName} - ${cardType.slug}`,
        value: cardType.slug,
      })) ?? []
    );
  }, [filteredCardTypes]);

  return {
    currentCardType:
      filteredCardTypes?.find((cardType) => cardType.slug === actualSlug) ??
      filteredCardTypes?.find((cardType) => cardType.id === id),
    cardTypes: filteredCardTypes,
    allCardTypes: cardTypes,
    isLoadingCardTypes: isLoading,
    cardTypeOptions,
  };
};
