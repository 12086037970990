import {
  type ComboboxItem,
  type ComboboxProps,
  Loader,
  type MantineStyleProps,
  ScrollArea,
} from '@mantine/core';
import { CheckIcon, Combobox, Group, Pill, PillsInput, useCombobox } from '@mantine/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DisplayOptions } from './shared/DisplayOptions';
import { type IBaseFormInputProps } from './shared/FormInputTypes';
import { Label } from './shared/Label';
import { SelectItemComponent } from './shared/SelectItemComponent';
import { parseFormErrorMessage } from './shared/error-message.util';
import { selectUtils } from './shared/select.utils';

export type FormMultiSelectProps<T extends ComboboxItem = ComboboxItem> = ComboboxProps &
  MantineStyleProps &
  IBaseFormInputProps & {
    placeholder?: string;
    icon?: React.ReactNode;
    creatable?: boolean;
    data: T[];
    loading?: boolean;
    ItemComponent?: typeof SelectItemComponent;
    onCreate?: (item: string) => void;
    getCreateLabel?: (item: string) => string;
    onSearchChange?: (value: string) => void;
    onChange?: (values: string[]) => void;
    filterFn?: (item: T, search: string) => boolean;
  };

export const FormMultiSelect = <T extends ComboboxItem>({
  data: originalData,
  required,
  description,
  name,
  placeholder,
  label,
  icon,
  loading,
  creatable = false,
  ItemComponent = SelectItemComponent,
  disabled,
  readOnly,
  getCreateLabel,
  onSearchChange,
  onCreate,
  hideError,
  variant,
  size,
  filterFn = selectUtils.filterFn,
  onChange: handleChange,
  ...props
}: FormMultiSelectProps<T>) => {
  const { t } = useTranslation();
  const { control, getValues } = useFormContext<Record<string, string[]>>();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });
  const {
    field: { value, onChange, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = parseFormErrorMessage(error?.message);
  const [search, setSearch] = useState('');
  const [data, setData] = useState<T[]>(originalData);

  useEffect(() => {
    setData(originalData);
  }, [originalData]);
  const exactOptionMatch = data.some((item) => item.label === search);

  const handleValueSelect = useCallback(
    (val: string) => {
      setSearch('');
      const currentValues: string[] = getValues(name) ?? [];
      if (val === '$create') {
        const created = onCreate ? onCreate(search) : search;
        if (!created) return; // TODO - show error
        const createdOption = { label: created, value: created } as T;
        setData((current) => [...current, createdOption]);
        onChange([...currentValues, created]);
        handleChange?.([...currentValues, created]);
      } else {
        const updatedValues = currentValues.includes(val)
          ? currentValues.filter((v) => v !== val)
          : [...currentValues, val];

        onChange(updatedValues);
        handleChange?.(updatedValues);
      }
    },
    [search],
  );

  const handleValueRemove = useCallback((val: string) => {
    const values: string[] = getValues(name);

    onChange(values.filter((v) => v !== val));
    handleChange?.(values.filter((v) => v !== val));
  }, []);

  const currentValues: string[] = getValues(name) ?? [];
  const values = useMemo(() => {
    return currentValues.map((item) => {
      const option = data.find((option) => option.value === item);
      return (
        <Pill
          key={item}
          withRemoveButton={!disabled && !readOnly}
          onRemove={() => handleValueRemove(item)}
        >
          {option?.label ?? item}
        </Pill>
      );
    });
  }, [currentValues, data]);

  const options = useMemo(
    () =>
      data
        .filter((item) => filterFn(item, search))
        .map((item) => (
          <Combobox.Option
            value={item.value}
            key={item.value}
            active={currentValues.includes(item.label)}
          >
            <Group gap="sm">
              {currentValues.find((v) => v === item.value) ? <CheckIcon size={12} /> : null}
              {ItemComponent ? <ItemComponent query={search} {...item} /> : item.label}
            </Group>
          </Combobox.Option>
        )),
    [currentValues, data, search],
  );

  const createLabel = useMemo<string>(
    () => (getCreateLabel ? getCreateLabel(search) : t('Create {{search}}', { search })),
    [search],
  );

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={false}
      disabled={disabled ?? readOnly}
      {...props}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          disabled={disabled ?? readOnly}
          id={name}
          label={
            label ? <Label label={label} description={description} required={required} /> : undefined
          }
          styles={{ label: { display: 'block' } }}
          leftSection={loading ? <Loader size="sm" /> : icon}
          error={hideError ? Boolean(errorMessage) : errorMessage}
          onClick={() => combobox.openDropdown()}
          variant={variant}
          size={size}
        >
          <Pill.Group>
            {values}
            <Combobox.EventsTarget>
              <PillsInput.Field
                readOnly={disabled ?? readOnly}
                placeholder={placeholder ? placeholder : t('Select...')}
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                  onSearchChange?.(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault();

                    const itemToRemove = currentValues[currentValues.length - 1];
                    if (itemToRemove) handleValueRemove(itemToRemove);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>
      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize mah={200} type="auto">
            <DisplayOptions
              search={search}
              options={options}
              createLabel={createLabel}
              exactOptionMatch={exactOptionMatch}
              creatable={creatable}
            />
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
