import { z } from 'zod';

export const fieldSettingSchema = z
  .object({
    // CURRENCY
    currency: z.enum(['USD', 'ILS', 'GBP', 'EUR', 'JPY', 'CAD', 'PLN']).optional(),
    // CARD_LOOKUP
    lookupId: z.string().optional(),
  })
  .optional();

export type IFieldSetting = z.infer<typeof fieldSettingSchema>;
