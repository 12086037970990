import { api } from '~/trpc';

export const useTokens = () => {
  const {
    data: tokens,
    isFetching: isTokensLoading,
    ...rest
  } = api.apiTokens.getAll.useQuery(undefined, {
    gcTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  });

  return { tokens, isTokensLoading, ...rest };
};
