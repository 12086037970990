import { DirectionProvider, TextInput, type TextInputProps } from '@mantine/core';
import { isEmptyString } from 'is-what';
import { useController, useFormContext } from 'react-hook-form';
import { regexSchemas } from '~/utils/regex';
import { type IBaseFormInputProps } from '../shared/FormInputTypes';
import { Label } from '../shared/Label';
import { parseFormErrorMessage } from '../shared/error-message.util';
import style from './Input.module.css';
type FormInputProps = TextInputProps & IBaseFormInputProps;

export const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  description,
  required = false,
  leftSection,
  hideError = false,
  dir,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { value, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = parseFormErrorMessage(error?.message);
  const text = !value || isEmptyString(value) ? props.placeholder : value;
  const isLtr =
    typeof dir === 'string'
      ? dir === 'ltr'
      : Boolean(typeof text === 'string' && regexSchemas.startsWithEnglishAndNumbers.test(text));

  return (
    <DirectionProvider initialDirection={isLtr ? 'ltr' : 'rtl'}>
      <TextInput
        className={style.input}
        id={name}
        value={(value as string) || ''}
        label={label ? <Label label={label} description={description} required={required} /> : undefined}
        error={hideError ? Boolean(errorMessage) : errorMessage}
        dir={isLtr ? 'ltr' : 'rtl'}
        {...(isLtr ? { rightSection: leftSection } : { leftSection })}
        {...rest}
        {...props}
        styles={{ label: { width: '100%' }, input: { textAlign: isLtr ? 'left' : 'right' } }}
      />
    </DirectionProvider>
  );
};
