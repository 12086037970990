import { type z } from 'zod';
import type { FieldType } from '~db/types';
import type {
  booleanFilter,
  clientDateFilter,
  idFilter,
  listFilter,
  numberFilter,
  searchSingleFilterSchema,
  textFilter,
} from '../api/schema/search.schema';

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC',
}
export type ESortOrder = `${SortOrder}`;

export const filterTypes = ['TEXT', 'DATE', 'NUMBER', 'BOOLEAN', 'LIST', 'ID', 'AGE'] as const;
export type IFilterType = (typeof filterTypes)[number];

export const filterTypesOperatorsMap = {
  TEXT: ['CONTAINS', 'EQUAL', 'EXISTS', 'NOT_EXISTS'],
  DATE: ['BETWEEN', 'NOT_BETWEEN', 'EXISTS', 'NOT_EXISTS'],
  NUMBER: ['BETWEEN', 'NOT_BETWEEN', 'GREATER_THAN', 'LESS_THAN', 'EQUAL', 'EXISTS', 'NOT_EXISTS'],
  BOOLEAN: ['EQUAL', 'EXISTS', 'NOT_EXISTS'],
  LIST: ['IN', 'NOT_IN'],
  ID: ['EQUAL', 'EXISTS', 'NOT_EXISTS'],
  AGE: ['BETWEEN', 'NOT_BETWEEN', 'GREATER_THAN', 'LESS_THAN', 'EQUAL', 'EXISTS', 'NOT_EXISTS'],
} as const;

export type IFilterTypeOperatorsMap = {
  [K in IFilterType]: (typeof filterTypesOperatorsMap)[K][number];
};

export type IJsonColumDefinition = {
  key: string;
  accessor: string;
  type?: FieldType;
};

export type ISort<Model extends Record<string, unknown>> = {
  field: keyof Model & string;
  order: ESortOrder;
  json?: IJsonColumDefinition;
};

export type ColumnVisibility = Record<string, boolean>;

export type IPagination = {
  limit: number;
  cursor?: number; // cursor is equal to skip -> TRPC requirements
};
export type IBooleanFilter = z.input<typeof booleanFilter>;
export type ITextFilter = z.input<typeof textFilter>;
export type IDateFilter = z.input<typeof clientDateFilter>;
export type INumberFilter = z.input<typeof numberFilter>;
export type IListFilter = z.input<typeof listFilter>;
export type IIdFilter = z.input<typeof idFilter>;

export type ISingleFilter = z.input<typeof searchSingleFilterSchema>;
export type ISingleFilterOutput = z.output<typeof searchSingleFilterSchema>;

export type IFilter<Model extends Record<string, unknown>> = Partial<Record<keyof Model, ISingleFilter>>;

export type ISearchRequest<
  Model extends Record<string, unknown>,
  ExtendedModel extends Record<string, unknown> = Model,
> = {
  orgId: string;
  filter?: IFilter<ExtendedModel>;
  // timeZone: TimeZone; // TODO: #8695ww1h3 handle dynamic timezones
  sort?: ISort<ExtendedModel>[];
  requiredFilters?: IFilter<Model>;
  globalFilters?: IFilter<Model>;
  columnVisibility?: ColumnVisibility;
} & IPagination;

export type ISearchResponse<Model, Summary extends Record<string, number> = Record<string, number>> = {
  results: Model[];
  total: number;
  nextCursor: number;
  summary?: Summary;
};
