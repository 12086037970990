import { z } from 'zod';
import { filterTypesOperatorsMap } from '~/server/services/types';
import { relativeTimeOptions } from '~/utils/date-time.service';

const stringNullable = z.string().optional().nullable();

const jsonSchema = z.object({
  key: z.string(),
  accessor: z.string(),
});

export const textFilter = z.object({
  type: z.literal('TEXT'),
  operator: z.enum(filterTypesOperatorsMap.TEXT).default('CONTAINS').optional(),
  value: z.string().optional(),
  json: jsonSchema.optional(),
});

const dateValues = [
  z.tuple([
    z
      .string()
      .transform((val) => new Date(val))
      .nullable(),
    z
      .string()
      .transform((val) => new Date(val))
      .nullable(),
  ]),
  z.tuple([z.date().nullable(), z.date().nullable()]),
  z.tuple([z.enum(relativeTimeOptions)]),
  z.null(),
] as const;

export const clientDateFilter = z.object({
  type: z.literal('DATE'),
  operator: z.enum(filterTypesOperatorsMap.DATE).default('BETWEEN').optional(),
  value: z.union(dateValues).optional(),
  json: jsonSchema.optional(),
});

export const dateFilter = clientDateFilter.extend({
  value: z.union([...dateValues, z.literal('$date')]),
});

export const numberFilter = z.object({
  type: z.literal('NUMBER'),
  operator: z.enum(filterTypesOperatorsMap.NUMBER).default('BETWEEN').optional(),
  value: z.tuple([z.number().nullable(), z.number().nullable()]).optional(),
  json: jsonSchema.optional(),
});

export const booleanFilter = z.object({
  type: z.literal('BOOLEAN'),
  operator: z.enum(filterTypesOperatorsMap.BOOLEAN).default('EQUAL').optional(),
  value: z.enum(['true', 'false']).optional(),
  json: jsonSchema.optional(),
});

export const listFilter = z.object({
  type: z.literal('LIST'),
  operator: z.enum(filterTypesOperatorsMap.LIST).default('IN').optional(),
  value: z.array(z.string()).optional(),
  json: jsonSchema.optional(),
});

export const idFilter = z.object({
  type: z.literal('ID'),
  operator: z.enum(filterTypesOperatorsMap.ID).default('EQUAL').optional(),
  value: z.union([z.array(z.string()), stringNullable]).optional(),
  json: jsonSchema.optional(),
});

export const ageFilter = z.object({
  type: z.literal('AGE'),
  operator: z.enum(filterTypesOperatorsMap.AGE).default('BETWEEN').optional(),
  value: z.tuple([z.number().nullable(), z.number().nullable()]).optional(),
  json: jsonSchema.optional(),
});

export const searchSingleFilterSchema = z.union([
  textFilter,
  dateFilter,
  ageFilter,
  numberFilter,
  booleanFilter,
  listFilter,
  idFilter,
]);

export const searchFilterSchema = z.record(searchSingleFilterSchema).default({});
export const sortSchema = z.object({ field: z.string(), order: z.enum(['ASC', 'DESC']) });
export const searchSortSchema = z.array(sortSchema);

export const searchSchema = z.object({
  limit: z.number().max(250).min(1).optional().default(10),
  cursor: z.number().optional(),
  filter: searchFilterSchema.optional(),
  sort: searchSortSchema.optional(),
  columnVisibility: z.record(z.boolean()).optional(),
});

export type ISearchSchema = z.input<typeof searchSchema>;
