import { type ComboboxItem } from '@mantine/core';

const filterFn = <T extends ComboboxItem>(item: T, search: string) => {
  const trimmedSearch = search.trim().toLocaleLowerCase();
  return (
    item.label.toLocaleLowerCase().includes(trimmedSearch) ||
    item.value.toLocaleLowerCase().includes(trimmedSearch)
  );
};

export const selectUtils = {
  filterFn,
};
