import { Combobox } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const DisplayOptions: React.FC<{
  search: string;
  options: JSX.Element[];
  loading?: boolean;
  creatable?: boolean;
  exactOptionMatch?: boolean;
  createLabel?: string;
}> = ({ search, options, loading, creatable, exactOptionMatch, createLabel }) => {
  const { t } = useTranslation();

  if (loading && options.length === 0) {
    return <Combobox.Empty>{t('Loading...')}</Combobox.Empty>;
  }

  if (options.length > 0) {
    return options;
  }

  if (search.trim().length === 0) {
    return <Combobox.Empty>{t('No results found')}</Combobox.Empty>;
  }

  if (!creatable) {
    return (
      <Combobox.Empty>
        {t('Nothing found matching the search word "{{search}}"', { search })}
      </Combobox.Empty>
    );
  }

  if (creatable && !exactOptionMatch) {
    return <Combobox.Option value="$create">{createLabel}</Combobox.Option>;
  }

  return null;
};
