import { z } from 'zod';

const customFields = z.record(
  z.union([z.undefined(), z.null(), z.string(), z.number(), z.boolean(), z.date(), z.array(z.string())]),
);

const updateRelatedValues = z.object({
  cardId: z.string(),
  relatedCardId: z.string(),
  values: customFields,
});
export const cardSchemas = {
  updateRelatedValues,
  customFields,
};
