import { type DefaultMantineColor } from '@mantine/core';
import { type TFunction } from 'i18next';
import { type ICreateField } from '~/server/api/schemas';
import { cardsTable, priorityEnum } from '../../../../db/schema';
import { type Card } from '../../../../db/types';

const systemFields = ['id', 'orgId', 'cardTypeId', 'customFields'] as const;
const baseFields = ['name', 'description', 'updatedAt', 'createdAt', 'updatedBy', 'createdBy'];
const protectedFields = Object.keys(cardsTable);
const isProtectedSystemField = (system: string) => protectedFields.includes(system);
export type LeanCard = Omit<Card, (typeof systemFields)[number]>;
const mapLifecycleStageToColor: Record<string, DefaultMantineColor> = {
  CUSTOMER: 'green',
  EVANGELIST: 'green',
  LEAD: 'blue',
  MARKETING_QUALIFIED_LEAD: 'blue',
  OPPORTUNITY: 'cyan',
  OTHER: 'gray',
  SALES_QUALIFIED_LEAD: 'blue',
  SUBSCRIBER: 'green',
};

const mapStatusToColor: Record<string, DefaultMantineColor> = {
  NEW: 'blue',
  IN_PROGRESS: 'cyan',
  ATTEMPTED_TO_CONTACT: 'orange',
  BAD_TIMING: 'gray',
  CONNECTED: 'green',
  OPEN: 'blue',
  OPEN_DEAL: 'green',
  PAYING_CUSTOMER: 'green',
  PILOT: 'cyan',
  UNQUALIFIED: 'gray',
};

export type FieldProps = Omit<ICreateField, 'cardTypeId'>;
type PreDefinedField = Omit<FieldProps, 'preDefinedColumn'> & {
  system: keyof LeanCard;
};

const createPreDefinedField = (field: FieldProps, preDefinedColumn = true): FieldProps => ({
  ...field,
  preDefinedColumn,
});

const getPreDefinedFields = (t: TFunction<'fieldsComponent'>): FieldProps[] => {
  const fields: PreDefinedField[] = [
    {
      system: 'name',
      type: 'TEXT',
      description: t('Name'),
      label: t('Name'),
      required: true,
    },
    { system: 'description', type: 'TEXTAREA', description: t('Description'), label: t('Description') },
    {
      system: 'updatedAt',
      internal: true,
      type: 'DATE',
      description: t('Updated at'),
      label: t('Updated at'),
    },
    {
      system: 'createdAt',
      internal: true,
      type: 'DATE',
      description: t('Created at'),
      label: t('Created at'),
    },
    {
      system: 'updatedBy',
      internal: true,
      type: 'LOOKUP_USER',
      description: t('Updated by'),
      label: t('Updated by'),
    },
    {
      system: 'createdBy',
      internal: true,
      type: 'LOOKUP_USER',
      description: t('Created by'),
      label: t('Created by'),
    },
    { system: 'email', type: 'EMAIL', description: t('Email address'), label: t('Email') },
    { system: 'avatar', type: 'IMAGE_URL', description: t('Avatar image link'), label: t('Avatar') },
    {
      system: 'leadStatus',
      type: 'SINGLE_SELECT',
      description: t('Lead status'),
      label: t('Lead status'),
      options: Object.keys(mapStatusToColor).map((v) => ({
        label: t(`leadStatus.${v}` as 'leadStatus.IN_PROGRESS'),
        value: v,
        color: mapStatusToColor[v],
      })),
    },
    {
      system: 'socialSecurityNumber',
      type: 'TEXT',
      description: t('Social security number'),
      label: t('SSN'),
    },
    { system: 'phone', type: 'PHONE', description: t('Phone number'), label: t('Phone') },
    { system: 'address', type: 'TEXT', description: t('Address'), label: t('Address') },
    { system: 'city', type: 'TEXT', description: t('City'), label: t('City') },
    { system: 'state', type: 'TEXT', description: t('State'), label: t('State') },
    { system: 'zip', type: 'TEXT', description: t('Zip code'), label: t('Zip code') },
    { system: 'postalCode', type: 'TEXT', description: t('Postal code'), label: t('Postal code') },
    { system: 'country', type: 'TEXT', description: t('Country'), label: t('Country') },
    {
      system: 'dateOfBirth',
      type: 'DATE',
      description: t('Date of birth'),
      label: t('Date of birth'),
    },
    { system: 'jobTitle', type: 'TEXT', description: t('Job title'), label: t('Job title') },
    { system: 'website', type: 'URL', description: t('Website'), label: t('Website') },
    { system: 'industry', type: 'TEXT', description: t('Industry'), label: t('Industry') },
    { system: 'note', type: 'TEXT', description: t('Note'), label: t('Note') },
    { system: 'fax', type: 'PHONE', description: t('Fax'), label: t('Fax') },
    { system: 'companyName', type: 'TEXT', description: t('Company name'), label: t('Company name') },
    { system: 'language', type: 'TEXT', description: t('Language'), label: t('Language') },
    { system: 'owner', type: 'LOOKUP_USER', description: t('Owner'), label: t('Owner') },
    { system: 'source', type: 'TEXT', description: t('Source'), label: t('Source') },
    { system: 'medium', type: 'TEXT', description: t('Medium'), label: t('Medium') },
    { system: 'campaign', type: 'TEXT', description: t('Campaign'), label: t('Campaign') },
    { system: 'comment', type: 'TEXT', description: t('Comment'), label: t('Comment') },
    { system: 'companyId', type: 'TEXT', description: t('Company ID'), label: t('Company ID') },
    { system: 'age', type: 'AGE', description: t('Age'), label: t('Age') },
    {
      system: 'yearFounded',
      type: 'NUMBER',
      description: t('Year founded'),
      label: t('Year founded'),
    },
    {
      system: 'totalRevenue',
      type: 'CURRENCY',
      description: t('Total revenue'),
      label: t('Total revenue'),
    },
    {
      system: 'annualRevenue',
      type: 'CURRENCY',
      description: t('Annual revenue'),
      label: t('Annual revenue'),
    },
    {
      system: 'numberOfEmployees',
      type: 'NUMBER',
      description: t('Number of employees'),
      label: t('Number of employees'),
    },
    {
      system: 'priority',
      type: 'SINGLE_SELECT',
      label: t('Priority'),
      description: t('Priority'),
      options: priorityEnum.enumValues.map((v) => ({ value: v, label: t(`priority.${v}`) })),
    },
    {
      system: 'lifecycleStage',
      type: 'SINGLE_SELECT',
      description: t('Lifecycle stage'),
      label: t('Lifecycle stage'),
      options: Object.keys(mapLifecycleStageToColor).map((v) => ({
        label: t(`lifecycleStage.${v}` as 'lifecycleStage.CUSTOMER'),
        value: v,
      })),
    },
  ];
  return fields.map((f) => createPreDefinedField(f));
};

const getProductFields = (t: TFunction<'fieldsComponent'>): FieldProps[] => {
  const fields: FieldProps[] = [
    // price
    { system: 'price', type: 'CURRENCY', description: t('Price'), label: t('Price') },
    // sku
    { system: 'sku', type: 'TEXT', description: t('SKU'), label: t('SKU') },
    // stock
    { system: 'stock', type: 'NUMBER', description: t('Stock'), label: t('Stock') },
  ];

  return fields.map((f) => createPreDefinedField(f, false));
};

const getBaseFields = (t: TFunction<'fieldsComponent'>): FieldProps[] => {
  return getPreDefinedFields(t).filter((f) => baseFields.includes(f.system));
};

const missingFields = [
  'firstName',
  'lastName',
  'linkedIn',
  'twitter',
  'facebook',
  'instagram',
  'street',
  'timezone',
  'emailDomain',
  'twitterId',
  'companyId',
  'type',
  'isPublic',
];

export const templateFieldsUtils = {
  getPreDefinedFields,
  getBaseFields,
  getProductFields,
  missingFields,
  isProtectedSystemField,
  baseFields,
  mapLifecycleStageToColor,
  mapStatusToColor,
};
