import { createInsertSchema } from 'drizzle-zod';
import { z } from 'zod';
import { zodUtils } from '~/utils/zod-utils';
import {
  cardsOnCardsTable,
  cardsTable,
  cardsTableViewTable,
  fieldsTable,
  permissionsTable,
  plansTable,
  rolesTable,
  userRolesTable,
  userSettingsTable,
} from '../../../db/schema';
import { type cardLayoutSchema } from '../services/card-type/card-type-layouts/schema';
import { fieldSettingSchema } from '../services/card-type/field.schema';
import { cardSchemas } from '../services/card/card.schemas';
import { planSettingsSchema } from '../services/plan/types';
import { templateFieldsUtils } from '../services/templates/fields';
import { userSettingsOptionsSchema } from '../services/user-settings/types';
import { searchFilterSchema, searchSortSchema, sortSchema } from './schema/search.schema';

export const createFieldsToOmit = {
  createdAt: true,
  createdBy: true,
  updatedAt: true,
  updatedBy: true,
  orgId: true,
} as const;

// Fields

export const fieldSchema = createInsertSchema(fieldsTable, {
  options: z
    .array(z.object({ label: z.string(), value: z.string(), color: z.string().optional() }))
    .optional(),
  system: zodUtils.snakeCaseSchema,
  settings: fieldSettingSchema,
  defaultValues: z.array(z.string()).optional(),
})
  .omit(createFieldsToOmit)
  .refine(
    (schema) => {
      if (templateFieldsUtils.isProtectedSystemField(schema.system) && !schema.preDefinedColumn) {
        false;
      }

      return true;
    },
    {
      message: 'Cannot create a field with a protected system name',
    },
  );

export type ICreateField = z.infer<typeof fieldSchema>;

// Cards

export type ICreateCardLayoutInput = z.input<typeof cardLayoutSchema>;
export type ICreateCardLayoutOutput = z.output<typeof cardLayoutSchema>;

export const createCardSchema = createInsertSchema(cardsTable, {
  updatedAt: z.date().default(() => new Date()),
  customFields: cardSchemas.customFields,
}).omit(createFieldsToOmit);

export const createCardWithRelationSchema = createCardSchema.extend({
  relatedCardIds: z.array(z.string()).optional().nullable(),
  _related: createCardSchema.optional(),
});

export const updateCardSchema = createCardSchema.required({ id: true }).extend({
  name: z.string().optional(),
});

export type ICreateCard = z.infer<typeof createCardSchema>;
export type ICreateCardWithRelation = z.infer<typeof createCardWithRelationSchema>;
export type IUpdateCard = z.infer<typeof updateCardSchema>;

export const cardRelationSchema = createInsertSchema(cardsOnCardsTable, {
  values: cardSchemas.customFields,
}).omit({ createdAt: true, createdBy: true, updatedAt: true, updatedBy: true });
export type ICreateCardRelation = z.infer<typeof cardRelationSchema>;

// User Settings
export const updateUserSettingsSchema = createInsertSchema(userSettingsTable, {
  updatedAt: z.date().default(() => new Date()),
  settings: userSettingsOptionsSchema,
}).omit({ userId: true, orgId: true, createdAt: true, updatedAt: true, updatedBy: true });
export type ICreateUserSettings = z.input<typeof updateUserSettingsSchema>;

// Plan
export const updatePlanSchema = createInsertSchema(plansTable)
  .extend({ orgIdToUpdate: z.string(), settings: planSettingsSchema })
  .omit(createFieldsToOmit);
export type IUpdatePlan = z.infer<typeof updatePlanSchema>;

// Roles and Permissions
export const roleSchema = createInsertSchema(rolesTable, {
  updatedAt: z.date().default(() => new Date()),
}).omit(createFieldsToOmit);
export type ICreateRole = z.infer<typeof roleSchema>;

export const userRoleSchema = createInsertSchema(userRolesTable, {
  updatedAt: z.date().default(() => new Date()),
}).omit(createFieldsToOmit);

export type ICreateUserRole = z.infer<typeof userRoleSchema>;

export const permissionSchema = createInsertSchema(permissionsTable, {
  updatedAt: z.date().default(() => new Date()),
}).omit(createFieldsToOmit);
export type ICreatePermission = z.infer<typeof permissionSchema>;

export const upsertOrgAccessSchema = z.object({
  childOrgIds: z.array(z.string()),
  roleId: z.string(),
});
export type IUpsertOrgAccess = z.infer<typeof upsertOrgAccessSchema>;

// General
export const deleteSchema = z.object({ id: z.string() });
export const cardsTableViewSettings = z.object({
  globalFilter: z.string().optional(),
  columns: z
    .record(
      z.object({
        display: z.boolean(),
        order: z.number().optional(),
      }),
    )
    .optional(),
  filters: searchFilterSchema.optional(),
  sort: searchSortSchema.optional(),
  kanban: z
    .object({
      fields: z.array(z.string()).optional(),
      groupBy: z.string().optional(),
      sort: sortSchema.optional(),
    })
    .optional(),
  isHidden: z.boolean().optional(),
});
// Card table views
export const cardsTableViewSchema = createInsertSchema(cardsTableViewTable, {
  updatedAt: z.date().default(() => new Date()),
})
  .extend({
    settings: cardsTableViewSettings,
  })
  .omit(createFieldsToOmit);

export type ICreateCardsTableViewOutput = z.output<typeof cardsTableViewSchema>;
export type ICreateCardsTableViewInput = z.input<typeof cardsTableViewSchema>;

// Organization
export const invitationSchema = z.object({
  emailAddress: z.string().email(),
  orgRoleId: z.string(),
});

export type ICreateInvitation = z.infer<typeof invitationSchema>;

export const entitySchema = z.object({
  id: z.string(),
  orgId: z.string(),
});
