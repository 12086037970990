import { useOrganization } from '@clerk/nextjs';
import { useEffect } from 'react';
import { create } from 'zustand';

type OrganizationHookReturnType = ReturnType<typeof useOrganization>;
type Memberships = OrganizationHookReturnType['memberships'];

type MembershipsStore = {
  memberships: Memberships;
  setMembershipList: (memberships: Memberships) => void;
};

export const useMembershipsStore = create<MembershipsStore>((set) => ({
  setMembershipList: (membershipList) => set({ memberships: membershipList }),
  memberships: null,
}));

const input = { keepPreviousData: true, infinite: true };
export const useFetchOrgList = () => {
  const { memberships, isLoaded } = useOrganization({ memberships: input });
  const setMembershipList = useMembershipsStore((state) => state.setMembershipList);

  useEffect(() => {
    if (isLoaded && memberships?.data) {
      setMembershipList(memberships);
    }
  }, [isLoaded, memberships, memberships?.data, setMembershipList]);
};

export const useMemberships = () => {
  const { memberships } = useMembershipsStore();
  return memberships;
};
