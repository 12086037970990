import { z } from 'zod';
import { languageEnum } from '~db/schema';

export const userSettingsOptionsSchema = z
  .object({
    language: z.enum(languageEnum.enumValues).optional(),
    phone: z.string().optional(),
    notifications: z
      .object({
        email: z
          .object({
            enabled: z.boolean().default(true),
            mentions: z.boolean().default(false),
            meetings: z.boolean().default(true),
            tasks: z.boolean().default(true),
          })
          .default({}),
        sms: z.object({ enabled: z.boolean().default(false) }).default({}),
        web: z.object({ enabled: z.boolean().default(true) }).default({}),
      })

      .default({}),
    integrations: z.object({}).default({}),
  })
  .default({});

export type UserSettingsOptionsInput = z.input<typeof userSettingsOptionsSchema>;
export type UserSettingsOptionsOutput = z.output<typeof userSettingsOptionsSchema>;
