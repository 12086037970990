import { heIL } from '@clerk/localizations/he-IL';
import { huHU } from '@clerk/localizations/hu-HU';
import { type useClerk, type useUser } from '@clerk/nextjs';
import { LocalizationResource, type OrganizationMembershipResource } from '@clerk/types';
import { env } from '~/env.mjs';
import { Language } from '~db/types';

const localeMap: Partial<Record<Language, LocalizationResource | undefined>> = {
  he: heIL,
  hu: huHU,
};

const getFullName = (member: OrganizationMembershipResource) => {
  const { firstName, lastName } = member.publicUserData;
  if (!firstName && !lastName) return member.publicUserData.identifier;

  return [firstName, lastName].filter(Boolean).join(' ');
};

const getUserPrimaryEmail = (user?: ReturnType<typeof useUser>['user']) => {
  return user?.primaryEmailAddress?.emailAddress;
};

const getUserPrimaryPhone = (user?: ReturnType<typeof useUser>['user']) => {
  return user?.primaryPhoneNumber?.phoneNumber;
};

const getOrgLink = ({ orgId, clerk }: { orgId: string; clerk: ReturnType<typeof useClerk> }) => {
  const instanceId = clerk.session?.lastActiveToken?.jwt?.header.kid;
  return `https://dashboard.clerk.com/apps/${env.NEXT_PUBLIC_CLERK_APP_ID}/instances/${instanceId}/organizations/${orgId}`;
};

const getLocale = (locale: Language) => {
  return localeMap[locale];
};

export const clerkClientUtils = {
  getFullName,
  getUserPrimaryEmail,
  getUserPrimaryPhone,
  getOrgLink,
  getLocale,
};
